import 'appstart';
import 'SCSSEntries/country.scss';
import 'Vendors/knockout/knockout-pack';

import 'Utils/mere.js';
//import 'Utils/slider.js'; //??? //2021-06-14 Disabled
//import 'Modules/map-container';


import(/* webpackChunkName: "widgets-module" */ 'Widgets/widgetMoreItems.js');
import(/* webpackChunkName: "searchbar-module" */ 'searchbar');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/banner-cards.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/destinationsSlider.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/frontpageHotelThemes.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/destinationIntro.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/destinationSubpages.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/destinationDestinations.js');
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/lowCostCalendar.js'); // temp test lowCostCalendar
import(/* webpackChunkName: "sections-module" */ 'Modules/sections/hotelList.js');



//AppLoader.setReady();
